import React from "react";

import { PublicLayout } from "../components/layouts/PublicLayout";
import { RecoverView } from "../containers/recover/RecoverView";

const RecoverPage = () => {
    return (
        <PublicLayout path="/recover/:userId">
            <RecoverView />
        </PublicLayout>
    );
};

export default RecoverPage;