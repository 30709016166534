import { Box, Heading, Stack, Text, VStack } from "@chakra-ui/layout";
import { HStack, useToast } from "@chakra-ui/react";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { useForm } from "react-hook-form";
import { navigate } from "gatsby";

import { $length, $string } from "foundation-ts/commons";

import { $g1Color } from "../../@chakra-ui/gatsby-plugin/G1Style";

import { PasswordField } from "../../components/PasswordField/PasswordField";
import { ComplexPasswordField } from "../../components/PasswordField/ComplexPasswordField";
import { G1Button } from "../../components/G1Button";
import VersionDisplay from "../../components/VersionDisplay";
import { config } from "../../config/config";
import { useChangePassword } from "../../hooks/useChangePassword";
import { defaultErrorToast, defaultSuccessToast } from "../../utils/toast";

export const RecoverView = () => {
    const { mutateAsync: changePassword } = useChangePassword() ;
    const {
        register,
        setValue,
        getValues,
        formState: { isSubmitting },
    } = useForm<any>({
        mode: "onChange",
        reValidateMode: "onChange",
    }) ;

    const toast = useToast() ;
    const [isValidatedConstraints, setValidatedConstraints] = useState(true) ;
    const [isPasswordsValid, setIsPasswordsValid] = useState(false) ;
    const [isSameOldAndNewPassword, setSameOldAndNewPassword] = useState(false) ;

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        try {
            e.preventDefault() ;
            const isValid = await changePassword({oldPassword: getValues("oldPassword"), verifyPassword: getValues("verifyPassword"), password: getValues("password")}) ;
            if (isValid) {
                toast(defaultSuccessToast("Changement effectué.")) ;
                navigate("/app/userProfile/account") ;
            }
        } catch (error) {
            toast(defaultErrorToast("Problème de correspondance sur les mots de passe")) ;
        }
    };

    const handleCancel = async e => {
        navigate("/app/userProfile/account") ;
    }

    const handleChangeOldPassword = async (e: ChangeEvent<HTMLInputElement>) => {
        const pwd = $string(e.currentTarget.value).trim() ;
        setValue("oldPassword", pwd) ;
        setSameOldAndNewPassword(getValues("oldPassword") === getValues("password")) ;
    }

    const handleChangePassword = (pwd: string, isValidatedConstraints: boolean) => {
        setValue("password", pwd) ;
        setValidatedConstraints(isValidatedConstraints) ;
        setIsPasswordsValid(getValues("password") === getValues("verifyPassword")) ;
        setSameOldAndNewPassword(getValues("oldPassword") === getValues("password")) ;
    }

    const handleChangeVerifyPassword = async (e: ChangeEvent<HTMLInputElement>) => {
        const pwd = $string(e.currentTarget.value).trim() ;
        setValue("verifyPassword", e.target.value.trim()) ;
        setIsPasswordsValid(getValues("password") === getValues("verifyPassword")) ;
    };

    return (
        <Box py="5%">
            <Box color={$g1Color('connection.logo.write')} bg={$g1Color('connection.logo.bg')} paddingTop="8" paddingBottom="2" px="8" maxW="md" mx="auto" rounded={{ base: "0", md: "2xl" }}>
                <VStack spacing="4" align="stretch">
                    <Heading as="h1" textAlign="center" size="lg" fontWeight="bold">
                        {config.app.title}
                    </Heading>
                    <Text textAlign="center" fontWeight="bold" fontSize="20px">
                        Changez votre mot de passe
                    </Text>
                    <form noValidate onSubmit={handleSubmit}>
                        <Stack spacing="6" bg={$g1Color('connection.form.bg')} color={$g1Color('connection.form.write')} py="8" px={{ base: "4", md: "10" }} shadow="base" rounded="lg" paddingBottom="1em">
                            <PasswordField 
                                required label="Ancien Mot de passe"
                                registerInput={{ ...register("oldPassword", { required: true }) }}
                                component="connection.form"
                                onChange={handleChangeOldPassword}
                            />
                            <ComplexPasswordField
                                registerInput={{ ...register("password", { required: true }) }}
                                passwordConstraints={config.passwordConstraints}
                                component="connection.form"
                                onChange={handleChangePassword}
                            />
                            <PasswordField
                                required label="Confirmation"
                                registerInput={{ ...register("verifyPassword", { required: true }) }}
                                component="connection.form"
                                isValid={isPasswordsValid}
                                onChange={handleChangeVerifyPassword}
                            />
                            <HStack justifyContent="space-evenly">
                                <G1Button component="connection" variant="cancel" title="Annuler" size="lg" onClick={handleCancel} />
                                <G1Button component="connection" variant="ok" title="Envoyer" type="submit" size="lg" isLoading={ isSubmitting } disabled={ isSubmitting || ($length(getValues("oldPassword")) == 0) || isSameOldAndNewPassword || !isPasswordsValid || !isValidatedConstraints } />
                            </HStack>
                      </Stack>
                  </form>
                  <VersionDisplay />
              </VStack>
          </Box>
      </Box>
  ) ;
} ;